import __request__ from 'routing/request';
interface Constraint {
    readonly type: string;
}
interface ContentPackUninstallDetails {
    readonly entities: NativeEntityDescriptor[];
}
interface ContentPackInstallationsResponse {
    readonly total: number;
    readonly installations: ContentPackInstallation[];
}
interface ContentPack {
    readonly rev: number;
    readonly v: string;
    readonly id: string;
}
interface ContentPackList {
    readonly total: number;
    readonly content_packs: ContentPack[];
    readonly content_packs_metadata: {
        readonly [_key: string]: {
            readonly [_key: string]: ContentPackMetadata;
        };
    };
}
interface ConstraintCheckResult {
    readonly fulfilled: boolean;
    readonly constraint: Constraint;
}
interface ContentPackInstallation {
    readonly content_pack_revision: number;
    readonly entities: NativeEntityDescriptor[];
    readonly created_at: string;
    readonly comment: string;
    readonly content_pack_id: string;
    readonly _id: unknown;
    readonly parameters: {
        readonly [_key: string]: ValueReference;
    };
    readonly created_by: string;
}
interface ContentPackRevisions {
    readonly content_pack_revisions: {
        readonly [_key: string]: ContentPack;
    };
    readonly constraints_result: {
        readonly [_key: string]: ConstraintCheckResult[];
    };
}
interface NativeEntityDescriptor {
    readonly content_pack_entity_id: string;
    readonly id: string;
    readonly found_on_system: boolean;
    readonly title: string;
    readonly type: ModelType;
}
interface ModelType {
    readonly name: string;
    readonly version: string;
}
interface ContentPackResponse {
    readonly constraints_result: ConstraintCheckResult[];
    readonly content_pack: ContentPack;
}
interface ContentPackInstallationRequest {
    readonly comment: string;
    readonly parameters: {
        readonly [_key: string]: ValueReference;
    };
}
interface ContentPackMetadata {
    readonly installation_count: number;
}
interface ValueReference {
    readonly '@value': Object;
    readonly '@type': unknown;
}
/**
 * List available content packs
 */
export function listContentPacks(): Promise<ContentPackList> {
    return __request__('GET', '/system/content_packs', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Upload a content pack
 * @param Requestbody Content pack
 */
export function createContentPack(Requestbody: ContentPack): Promise<unknown> {
    return __request__('POST', '/system/content_packs', Requestbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * List latest available content packs
 */
export function listLatestContentPacks(): Promise<ContentPackList> {
    return __request__('GET', '/system/content_packs/latest', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * List all revisions of a content pack
 * @param contentPackId Content pack ID
 */
export function listContentPackRevisions(contentPackId: string): Promise<ContentPackRevisions> {
    return __request__('GET', `/system/content_packs/${contentPackId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete all revisions of a content pack
 * @param contentPackId Content Pack ID
 */
export function deleteContentPack(contentPackId: string): Promise<void> {
    return __request__('DELETE', `/system/content_packs/${contentPackId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get details about the installations of a content pack
 * @param contentPackId Content pack ID
 */
export function listContentPackInstallationsById(contentPackId: string): Promise<ContentPackInstallationsResponse> {
    return __request__('GET', `/system/content_packs/${contentPackId}/installations`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Uninstall a content pack installation
 * @param contentPackId Content pack ID
 * @param installationId Installation ID
 */
export function deleteContentPackInstallationById(contentPackId: string, installationId: string): Promise<unknown> {
    return __request__('DELETE', `/system/content_packs/${contentPackId}/installations/${installationId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get details about which entities will actually be uninstalled
 * @param contentPackId Content pack ID
 * @param installationId Installation ID
 */
export function uninstallDetails(contentPackId: string, installationId: string): Promise<ContentPackUninstallDetails> {
    return __request__('GET', `/system/content_packs/${contentPackId}/installations/${installationId}/uninstall_details`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a revision of a content pack
 * @param contentPackId Content pack ID
 * @param revision Content pack revision
 */
export function getContentPackRevisions(contentPackId: string, revision: number): Promise<ContentPackResponse> {
    return __request__('GET', `/system/content_packs/${contentPackId}/${revision}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete one revision of a content pack
 * @param contentPackId Content Pack ID
 * @param revision Content Pack revision
 */
export function deleteContentPackBycontentPackIdAndrevision(contentPackId: string, revision: number): Promise<void> {
    return __request__('DELETE', `/system/content_packs/${contentPackId}/${revision}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Download a revision of a content pack
 * @param contentPackId Content pack ID
 * @param revision Content pack revision
 */
export function downloadContentPackRevisions(contentPackId: string, revision: number): Promise<ContentPack> {
    return __request__('GET', `/system/content_packs/${contentPackId}/${revision}/download`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Install a revision of a content pack
 * @param contentPackId Content pack ID
 * @param revision Content pack revision
 * @param installationrequest Content pack installation request
 */
export function installContentPack(contentPackId: string, revision: number, installationrequest: ContentPackInstallationRequest): Promise<ContentPackInstallation> {
    return __request__('POST', `/system/content_packs/${contentPackId}/${revision}/installations`, installationrequest, {}, {
        'Accept': ['application/json']
    });
}
