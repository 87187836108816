import __request__ from 'routing/request';
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface Collector {
    readonly service_type: string;
    readonly node_operating_system: string;
    readonly name: string;
    readonly validation_parameters: string;
    readonly executable_path: string;
    readonly execute_parameters: string;
    readonly default_template: string;
    readonly id: string;
}
interface CollectorSummaryResponse {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly collectors: CollectorSummary[];
    readonly query: string;
    readonly sort: string;
    readonly order: 'asc' | 'desc';
}
interface ValidationResult {
    readonly error_context: {
        readonly [_key: string]: string[];
    };
    readonly failed: boolean;
    readonly errors: {
        readonly [_key: string]: string[];
    };
}
interface CollectorSummary {
    readonly service_type: string;
    readonly node_operating_system: string;
    readonly name: string;
    readonly id: string;
}
/**
 * List all collectors
 */
export function listCollectors(): Promise<unknown> {
    return __request__('GET', '/sidecar/collectors', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Create a new collector
 */
export function createCollector(JSONbody: Collector): Promise<unknown> {
    return __request__('POST', '/sidecar/collectors', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * List a summary of all collectors
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function listSummary(sort: 'name' | 'id' | 'collector_id' = 'name', page: number = 1, per_page: number = 50, query?: string, order: 'asc' | 'desc' = 'asc'): Promise<CollectorSummaryResponse> {
    return __request__('GET', '/sidecar/collectors/summary', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Validates collector parameters
 * @param collector collector
 */
export function validateCollector(collector?: Collector): Promise<ValidationResult> {
    return __request__('POST', '/sidecar/collectors/validate', collector, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Show collector details
 */
export function getCollector(id: string): Promise<Collector> {
    return __request__('GET', `/sidecar/collectors/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update a collector
 */
export function updateCollector(id: string, JSONbody: Collector): Promise<unknown> {
    return __request__('PUT', `/sidecar/collectors/${id}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a collector
 */
export function deleteCollector(id: string): Promise<unknown> {
    return __request__('DELETE', `/sidecar/collectors/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Copy a collector
 */
export function copyCollector(id: string, name: string): Promise<unknown> {
    return __request__('POST', `/sidecar/collectors/${id}/${name}`, null, {}, {
        'Accept': ['application/json']
    });
}
