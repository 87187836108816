import __request__ from 'routing/request';
interface CodecTypeInfoMap {
    readonly [_key: string]: CodecTypeInfo;
}
interface CodecTypeInfo {
    readonly requested_configuration: {
        readonly [_key: string]: {
            readonly [_key: string]: Object;
        };
    };
    readonly name: string;
    readonly type: string;
}
/**
 * Get all codec types
 */
export function getAll(): Promise<CodecTypeInfoMap> {
    return __request__('GET', '/system/codecs/types/all', null, {}, {
        'Accept': ['application/json']
    });
}
