import __request__ from 'routing/request';
interface OutputSummary {
    readonly creator_user_id: string;
    readonly configuration: {
        readonly [_key: string]: Object;
    };
    readonly created_at: string;
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly content_pack: string;
}
interface OutputListResponse {
    readonly outputs: OutputSummary[];
    readonly total: number;
}
interface AddOutputRequest {
    readonly outputs: string[];
}
/**
 * Associate outputs with a stream
 * @param streamid The id of the stream whose outputs we want.
 */
export function add(streamid: string, JSONbody: AddOutputRequest): Promise<unknown> {
    return __request__('POST', `/streams/${streamid}/outputs`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a list of all outputs for a stream
 * @param streamid The id of the stream whose outputs we want.
 */
export function get(streamid: string): Promise<OutputListResponse> {
    return __request__('GET', `/streams/${streamid}/outputs`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete output of a stream
 * @param streamid The id of the stream whose outputs we want.
 * @param outputId The id of the output that should be deleted
 */
export function remove(streamid: string, outputId: string): Promise<void> {
    return __request__('DELETE', `/streams/${streamid}/outputs/${outputId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get specific output of a stream
 * @param streamid The id of the stream whose outputs we want.
 * @param outputId The id of the output we want.
 */
export function getBystreamidAndoutputId(streamid: string, outputId: string): Promise<OutputSummary> {
    return __request__('GET', `/streams/${streamid}/outputs/${outputId}`, null, {}, {
        'Accept': ['application/json']
    });
}
