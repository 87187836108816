import __request__ from 'routing/request';
interface InputTypeInfo {
    readonly requested_configuration: {
        readonly [_key: string]: {
            readonly [_key: string]: Object;
        };
    };
    readonly link_to_docs: string;
    readonly name: string;
    readonly is_exclusive: boolean;
    readonly type: string;
}
interface InputTypeInfoMap {
    readonly [_key: string]: InputTypeInfo;
}
interface InputTypesSummary {
    readonly types: {
        readonly [_key: string]: string;
    };
}
/**
 * Get all available input types of this node
 */
export function types(): Promise<InputTypesSummary> {
    return __request__('GET', '/system/inputs/types', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get information about all input types
 */
export function all(): Promise<InputTypeInfoMap> {
    return __request__('GET', '/system/inputs/types/all', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get information about a single input type
 */
export function info(inputType: string): Promise<InputTypeInfo> {
    return __request__('GET', `/system/inputs/types/${inputType}`, null, {}, {
        'Accept': ['application/json']
    });
}
