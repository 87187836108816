import __request__ from 'routing/request';
interface CallResult_VoidMap {
    readonly [_key: string]: CallResult_Void;
}
interface CallResult_Void {
    readonly response?: void;
    readonly server_error_message: string;
    readonly call_executed: boolean;
}
/**
 * Purge Lookup Table Cache on the cluster-wide level
 */
export function performPurge(idOrName?: string, key?: string): Promise<CallResult_VoidMap> {
    return __request__('POST', `/cluster/system/lookup/tables/${idOrName}/purge`, null, { 'key': key }, {
        'Accept': ['application/json']
    });
}
