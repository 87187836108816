import __request__ from 'routing/request';
type PipelineCompactSourceArray = PipelineCompactSource[];
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface BulkOperationFailure {
    readonly failure_explanation: string;
    readonly entity_id: string;
}
interface StreamListResponse {
    readonly total: number;
    readonly streams: StreamResponse[];
}
interface FilterOption {
    readonly title: string;
    readonly value: string;
}
interface PageListResponse_StreamDTO {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly defaults: EntityDefaults;
    readonly query: string;
    readonly elements: StreamDTO[];
    readonly attributes: EntityAttribute[];
    readonly sort: string;
    readonly order: 'asc' | 'desc';
}
interface GetConnectedPipelinesRequest {
    readonly stream_ids: string[];
}
interface UpdateStreamRequest {
    readonly index_set_id: string;
    readonly matching_type: string;
    readonly remove_matches_from_default_stream: boolean;
    readonly description: string;
    readonly title: string;
}
interface PipelineCompactSource {
    readonly id: string;
    readonly title: string;
}
interface CreateStreamRequest {
    readonly index_set_id: string;
    readonly matching_type: 'AND' | 'OR';
    readonly remove_matches_from_default_stream: boolean;
    readonly description: string;
    readonly rules: CreateStreamRuleRequest[];
    readonly title: string;
    readonly content_pack: string;
}
type stringArray = string[];
interface anyMap {
    readonly [_key: string]: unknown;
}
interface anyMapMap {
    readonly [_key: string]: anyMap;
}
interface BulkOperationResponse {
    readonly failures: BulkOperationFailure[];
    readonly successfully_performed: number;
    readonly errors: string[];
}
interface EntityAttribute {
    readonly related_property: string;
    readonly filterable: boolean;
    readonly related_collection: string;
    readonly hidden: boolean;
    readonly sortable: boolean;
    readonly id: string;
    readonly type: 'STRING' | 'DATE' | 'DOUBLE' | 'INT' | 'LONG' | 'OBJECT_ID' | 'BOOLEAN';
    readonly title: string;
    readonly searchable: boolean;
    readonly filter_options: FilterOption[];
}
interface StreamCreatedResponse {
    readonly stream_id: string;
}
interface StreamRule {
    readonly field: string;
    readonly stream_id: string;
    readonly description: string;
    readonly id: string;
    readonly validations: {
        readonly [_key: string]: Validator;
    };
    readonly inverted: boolean;
    readonly type: 'EXACT' | 'REGEX' | 'GREATER' | 'SMALLER' | 'PRESENCE' | 'CONTAINS' | 'ALWAYS_MATCH' | 'MATCH_INPUT';
    readonly fields: {
        readonly [_key: string]: Object;
    };
    readonly content_pack: string;
    readonly value: string;
}
interface StreamResponse {
    readonly creator_user_id: string;
    readonly outputs: OutputSummary[];
    readonly description: string;
    readonly created_at: string;
    readonly rules: StreamRule[];
    readonly is_editable: boolean;
    readonly title: string;
    readonly is_default: boolean;
    readonly content_pack: string;
    readonly index_set_id: string;
    readonly matching_type: string;
    readonly remove_matches_from_default_stream: boolean;
    readonly disabled: boolean;
    readonly id: string;
    readonly categories: string[];
}
interface AlertReceivers {
    readonly emails: string[];
    readonly users: string[];
}
interface PipelineCompactSourceArrayMap {
    readonly [_key: string]: PipelineCompactSourceArray;
}
interface TestMatchResponse {
    readonly rules: {
        readonly [_key: string]: boolean;
    };
    readonly matches: boolean;
}
interface Sorting {
    readonly id: string;
    readonly direction: 'ASC' | 'DESC';
}
interface CloneStreamRequest {
    readonly index_set_id: string;
    readonly remove_matches_from_default_stream: boolean;
    readonly description: string;
    readonly title: string;
}
interface EntityDefaults {
    readonly sort: Sorting;
}
interface OutputSummary {
    readonly creator_user_id: string;
    readonly configuration: {
        readonly [_key: string]: Object;
    };
    readonly created_at: string;
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly content_pack: string;
}
interface CreateStreamRuleRequest {
    readonly field: string;
    readonly description: string;
    readonly type: number;
    readonly inverted: boolean;
    readonly value: string;
}
interface BulkOperationRequest {
    readonly entity_ids: string[];
}
interface Validator {
}
interface AlertConditionSummary {
    readonly creator_user_id: string;
    readonly in_grace: boolean;
    readonly created_at: string;
    readonly id: string;
    readonly type: string;
    readonly title: string;
    readonly parameters: {
        readonly [_key: string]: Object;
    };
}
interface StreamDTO {
    readonly creator_user_id: string;
    readonly outputs: unknown[];
    readonly created_at: string;
    readonly description: string;
    readonly rules: StreamRule[];
    readonly is_editable: boolean;
    readonly alert_conditions: AlertConditionSummary[];
    readonly is_default: boolean;
    readonly title: string;
    readonly content_pack: string;
    readonly index_set_id: string;
    readonly alert_receivers: AlertReceivers;
    readonly matching_type: string;
    readonly remove_matches_from_default_stream: boolean;
    readonly disabled: boolean;
    readonly categories: string[];
    readonly id: string;
}
/**
 * Get a list of all streams
 */
export function get(): Promise<StreamListResponse> {
    return __request__('GET', '/streams', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Create a stream
 */
export function create(JSONbody: CreateStreamRequest): Promise<StreamCreatedResponse> {
    return __request__('POST', '/streams', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a bulk of streams
 */
export function bulkDelete(Entitiestoremove: BulkOperationRequest): Promise<BulkOperationResponse> {
    return __request__('POST', '/streams/bulk_delete', Entitiestoremove, {}, {
        'Accept': []
    });
}
/**
 * Pause a bulk of streams
 */
export function bulkPause(Streamstopause: BulkOperationRequest): Promise<BulkOperationResponse> {
    return __request__('POST', '/streams/bulk_pause', Streamstopause, {}, {
        'Accept': []
    });
}
/**
 * Resume a bulk of streams
 */
export function bulkResume(Streamstoresume: BulkOperationRequest): Promise<BulkOperationResponse> {
    return __request__('POST', '/streams/bulk_resume', Streamstoresume, {}, {
        'Accept': []
    });
}
/**
 * Get a list of all streams connected to a given index set
 */
export function getByIndexSet(indexSetId: string): Promise<StreamListResponse> {
    return __request__('GET', `/streams/byIndex/${indexSetId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a list of all enabled streams
 */
export function getEnabled(): Promise<StreamListResponse> {
    return __request__('GET', '/streams/enabled', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Assign multiple streams to index set
 */
export function assignToIndexSet(indexSetId: string, JSONbody: stringArray): Promise<unknown> {
    return __request__('PUT', `/streams/indexSet/${indexSetId}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a paginated list of streams
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getPage(sort: 'title' | 'description' | 'created_at' | 'updated_at' | 'status' = 'title', page: number = 1, per_page: number = 50, query?: string, filters?: stringArray, order: 'asc' | 'desc' = 'asc'): Promise<PageListResponse_StreamDTO> {
    return __request__('GET', '/streams/paginated', null, { 'page': page, 'per_page': per_page, 'query': query, 'filters': filters, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Get pipelines associated with specified streams
 */
export function getConnectedPipelinesForStreams(streamIds: GetConnectedPipelinesRequest): Promise<PipelineCompactSourceArrayMap> {
    return __request__('POST', '/streams/pipelines', streamIds, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a single stream
 */
export function getBystreamId(streamId: string): Promise<StreamResponse> {
    return __request__('GET', `/streams/${streamId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update a stream
 */
export function update(streamId: string, JSONbody: UpdateStreamRequest): Promise<StreamResponse> {
    return __request__('PUT', `/streams/${streamId}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a stream
 */
export function remove(streamId: string): Promise<void> {
    return __request__('DELETE', `/streams/${streamId}`, null, {}, {
        'Accept': []
    });
}
/**
 * Clone a stream
 */
export function cloneStream(streamId: string, JSONbody: CloneStreamRequest): Promise<StreamCreatedResponse> {
    return __request__('POST', `/streams/${streamId}/clone`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Pause a stream
 */
export function pause(streamId: string): Promise<void> {
    return __request__('POST', `/streams/${streamId}/pause`, null, {}, {
        'Accept': []
    });
}
/**
 * Get pipelines associated with a stream
 */
export function getConnectedPipelines(streamId: string): Promise<PipelineCompactSourceArray> {
    return __request__('GET', `/streams/${streamId}/pipelines`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Resume a stream
 */
export function resume(streamId: string): Promise<void> {
    return __request__('POST', `/streams/${streamId}/resume`, null, {}, {
        'Accept': []
    });
}
/**
 * Test matching of a stream against a supplied message
 */
export function testMatch(streamId: string, JSONbody: anyMapMap): Promise<TestMatchResponse> {
    return __request__('POST', `/streams/${streamId}/testMatch`, JSONbody, {}, {
        'Accept': []
    });
}
