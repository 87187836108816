import __request__ from 'routing/request';
interface IndexTemplateResponse {
    readonly template: Template;
    readonly name: string;
}
interface Template {
    readonly settings: {
        readonly [_key: string]: Object;
    };
    readonly mappings: {
        readonly [_key: string]: Object;
    };
    readonly index_patterns: string[];
    readonly order: number;
}
type IndexTemplateResponseArray = IndexTemplateResponse[];
/**
 * Get index templates for all index sets
 */
export function getAll(): Promise<IndexTemplateResponseArray> {
    return __request__('GET', '/system/indexer/indices/templates', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Updates the index templates for all index sets in Elasticsearch
 */
export function syncAll(): Promise<IndexTemplateResponseArray> {
    return __request__('POST', '/system/indexer/indices/templates/update', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get index template for the given index set
 */
export function get(indexSetId?: string): Promise<IndexTemplateResponse> {
    return __request__('GET', `/system/indexer/indices/templates/${indexSetId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Updates the index template for the given index set in Elasticsearch
 */
export function sync(indexSetId?: string): Promise<IndexTemplateResponse> {
    return __request__('POST', `/system/indexer/indices/templates/${indexSetId}/update`, null, {}, {
        'Accept': ['application/json']
    });
}
