import __request__ from 'routing/request';
interface PaginatedResponse_UserOverviewDTO {
    readonly [_key: string]: Object;
}
interface AuthzRoleDTO {
    readonly read_only: boolean;
    readonly permissions: string[];
    readonly name: string;
    readonly description: string;
    readonly id: string;
}
type stringArray = string[];
interface PaginatedResponse_AuthzRoleDTO {
    readonly [_key: string]: Object;
}
/**
 * Get a paginated list of all roles
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getList(sort: 'name' | 'description' = 'name', page: number = 1, per_page: number = 50, query?: string, order: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_AuthzRoleDTO> {
    return __request__('GET', '/authz/roles', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Get a paginated list roles for a user
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getListForUser(sort: 'name' | 'description' = 'name', username?: string, page: number = 1, per_page: number = 50, query?: string, order: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_AuthzRoleDTO> {
    return __request__('GET', `/authz/roles/user/${username}`, null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Get a single role
 */
export function get(roleId?: string): Promise<AuthzRoleDTO> {
    return __request__('GET', `/authz/roles/${roleId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a role
 */
export function remove(roleId?: string): Promise<void> {
    return __request__('DELETE', `/authz/roles/${roleId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Remove user from role
 */
export function removeUser(roleId?: string, username?: string): Promise<void> {
    return __request__('DELETE', `/authz/roles/${roleId}/assignee/${username}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a paginated list of users for a role
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getUsersForRole(sort: 'username' | 'full_name' | 'email' | 'name' = 'name', roleId?: string, page: number = 1, per_page: number = 50, query?: string, order: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_UserOverviewDTO> {
    return __request__('GET', `/authz/roles/${roleId}/assignees`, null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Add user to role
 */
export function addUser(roleId?: string, usernames?: stringArray): Promise<void> {
    return __request__('PUT', `/authz/roles/${roleId}/assignees`, usernames, {}, {
        'Accept': ['application/json']
    });
}
