import __request__ from 'routing/request';
interface AvailableOutputSummaryMap {
    readonly [_key: string]: AvailableOutputSummary;
}
interface OutputSummary {
    readonly creator_user_id: string;
    readonly configuration: {
        readonly [_key: string]: Object;
    };
    readonly created_at: string;
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly content_pack: string;
}
interface OutputListResponse {
    readonly outputs: OutputSummary[];
    readonly total: number;
}
interface Output {
    readonly creator_user_id: string;
    readonly configuration: {
        readonly [_key: string]: Object;
    };
    readonly created_at: string;
    readonly id: string;
    readonly type: string;
    readonly title: string;
    readonly content_pack: string;
}
interface AvailableOutputSummary {
    readonly human_name: string;
    readonly requested_configuration: {
        readonly [_key: string]: {
            readonly [_key: string]: Object;
        };
    };
    readonly link_to_docs: string;
    readonly name: string;
    readonly type: string;
}
interface AvailableOutputSummaryMapMap {
    readonly [_key: string]: AvailableOutputSummaryMap;
}
interface anyMap {
    readonly [_key: string]: unknown;
}
interface CreateOutputRequest {
    readonly configuration: {
        readonly [_key: string]: Object;
    };
    readonly streams: string[];
    readonly title: string;
    readonly type: string;
    readonly content_pack: string;
}
/**
 * Get a list of all outputs
 */
export function get(): Promise<OutputListResponse> {
    return __request__('GET', '/system/outputs', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Create an output
 */
export function create(JSONbody: CreateOutputRequest): Promise<unknown> {
    return __request__('POST', '/system/outputs', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all available output modules
 */
export function available(): Promise<AvailableOutputSummaryMapMap> {
    return __request__('GET', '/system/outputs/available', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get specific output
 * @param outputId The id of the output we want.
 */
export function getByoutputId(outputId: string): Promise<OutputSummary> {
    return __request__('GET', `/system/outputs/${outputId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update output
 * @param outputId The id of the output that should be deleted
 */
export function update(outputId: string, JSONbody: anyMap): Promise<Output> {
    return __request__('PUT', `/system/outputs/${outputId}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete output
 * @param outputId The id of the output that should be deleted
 */
export function remove(outputId: string): Promise<void> {
    return __request__('DELETE', `/system/outputs/${outputId}`, null, {}, {
        'Accept': ['application/json']
    });
}
