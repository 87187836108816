import __request__ from 'routing/request';
interface Decorator {
    readonly stream: string;
    readonly id: string;
    readonly type: string;
    readonly config: {
        readonly [_key: string]: Object;
    };
    readonly order: number;
}
interface ConfigurableTypeInfo {
    readonly human_name: string;
    readonly requested_configuration: {
        readonly [_key: string]: {
            readonly [_key: string]: Object;
        };
    };
    readonly link_to_docs: string;
    readonly name: string;
    readonly type: string;
}
interface DecoratorImpl {
    readonly stream: string;
    readonly id: string;
    readonly type: string;
    readonly config: {
        readonly [_key: string]: Object;
    };
    readonly order: number;
}
type DecoratorArray = Decorator[];
interface ConfigurableTypeInfoMap {
    readonly [_key: string]: ConfigurableTypeInfo;
}
/**
 * Returns all configured message decorations
 */
export function get(): Promise<DecoratorArray> {
    return __request__('GET', '/search/decorators', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Creates a message decoration configuration
 */
export function create(JSONbody: DecoratorImpl): Promise<Decorator> {
    return __request__('POST', '/search/decorators', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Returns all available message decorations
 */
export function getAvailable(): Promise<ConfigurableTypeInfoMap> {
    return __request__('GET', '/search/decorators/available', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update a decorator
 */
export function update(decoratorId: string, JSONbody: DecoratorImpl): Promise<Decorator> {
    return __request__('PUT', `/search/decorators/${decoratorId}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Create a decorator
 */
export function remove(decoratorId: string): Promise<void> {
    return __request__('DELETE', `/search/decorators/${decoratorId}`, null, {}, {
        'Accept': ['application/json']
    });
}
