import __request__ from 'routing/request';
interface KeyStoreDtoMap {
    readonly [_key: string]: KeyStoreDto;
}
interface CertificateDto {
    readonly subject: string;
    readonly valid_to: string;
    readonly subject_alternative_names: string[];
    readonly valid_from: string;
    readonly serial_number: number;
    readonly issuer: string;
}
interface KeyStoreDto {
    readonly certificates: {
        readonly [_key: string]: CertificateDto[];
    };
}
/**
 * Returns the certificates used by this node
 */
export function getCertificates(): Promise<KeyStoreDtoMap> {
    return __request__('GET', '/certificates', null, {}, {
        'Accept': ['application/json']
    });
}
